
import {
    apiTemplateAmazonSiteSiteTemplate,
    apiTemplateAmazonSiteDelTemplate,
    apiSrontabOperate,
} from "@/api/setting";
import { Component, Prop, Vue } from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
import { RequestPaging } from "@/utils/util";
import { PageMode } from "@/utils/type";
import LsDialog from "@/components/ls-dialog.vue";
@Component({
    components: {
        LsDialog,
        LsPagination,
    },
})
export default class Task extends Vue {
    // 分页
    pager: RequestPaging = new RequestPaging();
    apiTemplateAmazonSiteSiteTemplate = apiTemplateAmazonSiteSiteTemplate
    form: any = {
        id: ''
    }
    templateList = []

    // 获取列表
    async getList() {
        let res = await apiTemplateAmazonSiteSiteTemplate({
            site_id: this.form.id
        });
        this.templateList = res;
        // this.pager.request({
        //     callback: apiTemplateAmazonSiteSiteTemplate,
        //     params: {
        //         site_id: 1
        //     }
        // });
    }

    async onStop(row: any): Promise<void> {
        await apiSrontabOperate({
            id: row.id,
            operate: row.status == 1 ? "stop" : "start",
        });
        this.getList();
    }

    // 删除这个定时任务
    async onDel(id: any): Promise<void> {
        await apiTemplateAmazonSiteDelTemplate({ id: id });
        this.getList();
    }

    // 新增
    goTaskAdd() {
        this.$router.push({
            path: "/setting/site/site_template_edit",
            query: {
                site_id: this.$route.query.id,
                mode: PageMode["ADD"],
            },
        });
    }

    // 下载模板
    goDownLoad(item: any) {
        if(item?.template?.url) window.open(item.template.url, '_blank');
    }

    // 编辑
    goTaskEdit(id: number) {
        this.$router.push({
            path: "/setting/site/site_template_edit",
            query: {
                site_id: this.$route.query.id,
                template_id: id as any,
                mode: PageMode["EDIT"],
            },
        });
    }

    created() {
        const query: any = this.$route.query

        if (query.id) {
            this.form.id = query.id * 1
            this.getList();
        }
    }
}
